export const Home = () => {
  return (
    <>
      <div id="banner">
        <img src="./banner-img.png" />
      </div>
      <div id="about-book">
        <div>
          <div id="about-book-text">
            <div id="about-book-heading">About The Book</div>
            <div id="about-book-desc">I am committed to making the world a better place.</div>
            <div id="about-book-desc">What if we could end human suffering of all people and live in peace? What would that take? How could we truly transform all of our lives for the best? What would that take?</div>
            <div id="about-book-desc">I encourage you to ask yourself those question and read this book and find out! I want to see the world a better place and I've written this book for that reason.</div>
            <div id="about-book-charity">All profits go to charity...</div>
          </div>
          <div id="about-book-button">
            {/* <img src="./banner-img.png" /> */}
            <a href="https://www.amazon.com.au/dp/B0DB7NG9K8/" target="_blank"><button id="buy-book">See Book</button></a>
          </div>
        </div>
      </div>
      <div id="donations">
        <div id="donations-heading">Donations so far</div>
        <div>
          <div id="total-donated">Total donated: <span class="dollar">$0.00</span></div>
          <table>
            <tbody>
              <tr class="tr-border">
                <td class="charity">World Vision</td>
                <td>$0.00</td>
              </tr>
              <tr class="tr-border">
                <td class="charity">Doctors Without Borders</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td class="charity">Save The Children</td>
                <td>$0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div id="about-me">
        <div>
          <div id="about-book-text">
            <div id="about-book-heading">About Me</div>
            <div id="about-book-desc">My name is Cam. I am committed to making the world a better place. I am a force for Good.</div>
            <div id="about-book-desc">My most important values are humility, love and compassion. I believe in a better world, a world of love.</div>
            <div id="about-book-desc">I love every person on this planet, and beyond, including you ❤️</div>
            <div id="about-book-desc">You can email me <a id="email" href="mailto:aworldoflovecam@gmail.com">here</a></div>
          </div>
          <div id="me">
            <img src="./about-me-img.png" />
          </div>
        </div>
      </div>
      <div id="copyright">
        <div>Copyright &copy; 2024</div>
      </div>
    </>
  );
}